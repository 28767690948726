<template>
  <div class="tableNew">
    <div class="tableNew--head">
      <tableArea
        @updata="updata"
        :businessAreaId.sync="formData.businessAreaId"
      />
    </div>
    <div class="tableNew--body">
      <div class="tableNew--body__title">
        编辑桌台
        <div class="tableNew--body__title--del" v-if="businessTableId">
          <el-link icon="el-icon-delete-solid" @click="delTable" type="danger">删除</el-link>
        </div>
      </div>
      <img
        class="tableNew--body__img"
        src="@/assets/tableImg.png"
        alt=""
        srcset=""
      />
      <div class="tableNew--body__form">
        <el-form :model="formData" :rules="rules" ref="form" label-width="0">
          <el-form-item prop="businessTableMark">
            <div class="tableNew--body__form--item">
              <div class="tableNew--body__form--item__label">餐桌名称</div>
              <input
                type="text"
                @input="(e) => inputChange('businessTableMark', e)"
                :value="formData.businessTableMark"
                placeholder="请输入餐桌名称"
              />
            </div>
          </el-form-item>
          <el-form-item prop="seatNum">
            <div class="tableNew--body__form--item">
              <div class="tableNew--body__form--item__label">座位数量</div>
              <input
                type="text"
                @input="(e) => inputChange('seatNum', e)"
                :value="formData.seatNum"
                placeholder="请输入座位数量"
              />
            </div>
          </el-form-item>
        </el-form>
        <div class="tableNew--body__bottom">
          <div class="tableNew--body__bottom--button qrButton" @click="setQrcodeMark">
            {{
              qrcodeMark || formData.qrcodeId ? "点击更换二维码" : "扫码绑定"
            }}
          </div>
          <div class="tableNew--body__bottom--button" @click="submit">完成</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import businessTableArea from '@/service/businessTableArea.js'
import businessTableInfo from '@/service/businessTableInfo.js'
import tableArea from '../tableArea/index.vue'
import Barcode from '@/utils/barcode2.js'
import getUrlParams from '@/utils/getUrlParams.js'
import { mapState } from 'vuex'
export default {
  components: {
    tableArea
  },
  props: {
     businessTableId: {
      type: String,
      default: ''
     }
  },
  data () {
    return {
      qrcodeMark: '',
      formData: {
        businessTableMark: '',
        seatNum: '',
        businessAreaId: '',
        qrcodeId: ''
      },
      rules: {}
    }
  },
  watch: {
    formData () {
      console.log(this.formData)
    },
    businessTableId () {
      if (this.businessTableId) {
        this.getInfo()
      }
    }
  },
  computed: {
 ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  methods: {
    // 输入框赋值
    inputChange (name, e) {
      this.formData[name] = e.target.value
    },
    updata () {
      this.$emit('updata')
    },
    // 获取桌子详情
    async getInfo () {
        const res = await businessTableInfo.businessTableInfoGetById(this.businessTableId)
        this.formData = res.data
    },
    // 删除商桌
    async delTable () {
       const confirm = await this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        console.log(confirm)
        if (confirm === 'confirm') {
            const res = await businessTableInfo.businessTableInfoDel(this.businessTableId)
            if (res.success) {
              this.$emit('updataTable')
            }
        }
    },
    setQrcodeMark () {
        if (navigator.userAgent.indexOf('Html5Plus') > -1) {
          console.log(!!this.orderId)
          // eslint-disable-next-line no-unused-vars
          const barCodeDom = new Barcode({
            successCD: (authCode) => {
              this.qrcodeMark = getUrlParams(authCode).qrcodeMark
            }
          })
        } else {
          const scannerVideo = this.$scannerVideo({
            successCD: (authCode) => {
              this.qrcodeMark = getUrlParams(authCode).qrcodeMark
               scannerVideo.cancelPay()
            }
          })
        }
    },
    submit () {
      if (!this.formData.businessAreaId) {
         this.$message({
          message: '请选择区域',
          type: 'warning'
        })
        return
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const res = await businessTableInfo.businessTableInfoSave({
            ...this.formData,
            businessId: this.shopInfo.id
          })
          if (this.qrcodeMark) {
            await businessTableInfo.businessTableBind({
              businessTableId: res.data,
              qrcodeMark: this.qrcodeMark
            })
          }
          this.formData = {
            businessTableMark: '',
            seatNum: '',
            businessAreaId: '',
            qrcodeId: ''
          }
          this.qrcodeMark = ''
          this.$emit('update:businessTableId', '')
          this.$emit('updataTable')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tableNew {
  height: 100%;
  display: flex;
  flex-direction: column;
  &--body {
    flex: 1;
    min-height: 0px;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 20px;
    padding: 36px 28px;
    box-sizing: border-box;
    align-items: center;

    &__title {
      color: #000000;
      font-size: 20px;
      text-align: center;
      position: relative;
      width: 100%;
      &--del{
        position: absolute;
        right: 20px;
        top: 0;
      }
    }
    &__img {
      width: 66px;
      height: 66px;
      margin-top: 46px;
    }
    &__form {
      width: 100%;
      margin-top: 48px;
      &--item {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dfdfdf;
        &__label {
          color: #000000;
          font-size: 16px;
        }
        input {
          text-align: right;
          font-size: 14px;
          border: none;
          outline: none;
        }
      }
    }
    &__bottom {
      display: flex;
      margin-top: 26px;
      justify-content: center;
      align-items: center;
      margin-top: 150px;
      &--button {
        background: #ffbb12;
        border-radius: 8px;
        width: 144px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
::v-deep.el-form-item {
  margin-bottom: 0px !important;
}
.qrButton {
  margin-right: 128px;
}
</style>
