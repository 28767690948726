<template>
  <div class="tableGm">
    <div class="tableGm__left">
      <div class="tableGm__left--head">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane
            :label="item.businessAreaName"
            :name="item.id"
            :key="item.id"
            v-for="item in areaLs"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div
        class="tableGm__left--body"
        v-infinite-scroll="getList"
        :infinite-scroll-immediate="false"
        v-loading="loading"
      >
        <div class="tableGm__left--body__area">
          <areaItem
            v-for="item in list"
            :areaItem="item"
            :key="item.id"
            :showTableNum="false"
            :activeArea="businessTableId === item.id"
            @click="businessTableId = item.id"
          />
        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <div class="tableGm__right">
        <tableNew
          @updata="getAreaLs(false)"
          @updataTable="confirm"
          :businessTableId.sync="businessTableId"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import businessTableArea from '@/service/businessTableArea.js'
import Debounce from '@/utils/debounce.js'
import areaItem from '@/components/areaItem/index.vue'
import businessTableInfo from '@/service/businessTableInfo.js'
import tableNew from '@/views/tableGm/components/tableNew/index.vue'
import { mapState } from 'vuex'
const DebounceFun = new Debounce()
export default {
  components: {
    areaItem,
    tableNew
  },
  data () {
    return {
      value1: '',
      activeName: '',
      areaLs: [], // 区域列表
      finished: false, // 是否加载完成，
      loading: false, // 是否加载中,
      list: [], // 桌台数据
      status: 'loadmore',
      isEmptyTable: false,
      businessTableId: '',
      pageData: {
        pageNum: 1,
        pageSize: 20,
        businessAreaId: ''
      }
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo,
      message: (store) => store.login.message
    })
  },
  watch: {
    message: {
      handler () {
        DebounceFun.debounceCD(() => {
          console.log(12121)
        }, 1000)
      },
      deep: true
    }
  },
  mounted () {
    this.getAreaLs()
  },
  methods: {
    // 获取区域数据
    async getAreaLs (next = true) {
      const res = await businessTableArea.businessAreaList({
        businessAreaType: 0,
        businessId: this.shopInfo.id
      })
      this.areaLs = res.data
      if (next) {
        this.pageData.businessAreaId = res.data[0].id
        console.log(this.activeName)
        this.activeName = res.data[0].id
        this.getList()
      }
    },
    tabClick (e) {
      this.pageData.businessAreaId = this.activeName
      this.confirm()
    },
    // 重置数据并且重新请求桌台数据
    confirm () {
      this.finished = false
      this.loading = false
      this.list = []
      this.status = 'loadmore'
      this.pageData.pageNum = 1
      this.getList()
    },

    // 获取商户桌台列表
    async getList () {
      if (this.finished || this.loading || !this.pageData.businessAreaId) {
        return
      }
      const data = {
        ...this.pageData,
        businessId: this.shopInfo.id,
        keyword: this.keyword
      }
      this.loading = true
      this.status = 'loading'
      const res = await businessTableInfo.businessTableInfoPage(data)
      this.list = [...this.list, ...res.data.list]
      if (this.list.length >= res.data.total) {
        this.finished = true
        this.status = 'nomore'
      } else {
        this.status = 'loadmore'
      }
      this.pageData.pageNum = this.pageData.pageNum + 1
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tableGm {
  height: 100%;
  display: flex;
  &__left {
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    flex: 1;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    &--head {
      padding: 0px 20px;
      box-sizing: border-box;
      ::v-deep .el-tabs__item {
        height: 87px !important;
        line-height: 87px !important;
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        width: 154px;
        text-align: center;
        padding: 0px !important;
      }
      ::v-deep .el-tabs__active-bar {
        width: 154px !important;
        background-color: #ffbb12 !important;
      }
    }
    &--body {
      flex: 1;
      min-height: 0px;
      width: 100%;
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
      &__area {
        width: 100%;
      }
    }
  }
  &__right {
    width: 671px;
    height: 100%;
    margin-left: 56px;
  }
}
::v-deep .el-tabs__nav-next {
  line-height: 87px !important;
}
::v-deep .el-tabs__nav-prev {
  line-height: 87px !important;
}
</style>
