<template>
  <div class="tableArea">
    <div class="tableArea__head">选择区域</div>
    <div class="tableArea__body">
      <el-tag
      @click.native="setBusinessAreaId(item.id)"
      @dblclick.native="businessTableAreaLongpress(item)"
       @close="businessTableAreaClose(item.id)" v-for="item in areaLs" :key="item.id" closable :type="businessAreaId===item.id?'success':'info'">
        {{ item.businessAreaName }}
      </el-tag>
      <span class="tableArea__body--add" @click="businessTableAreaLongpress({})">新建区域</span>
    </div>
  </div>
</template>
<script>
import businessTableArea from '@/service/businessTableArea.js'
import { mapState } from 'vuex'
export default {
  props: {
   businessAreaId: {
    type: String,
    default: ''
   }
  },
  data () {
    return {
       areaLs: []
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  created () {
    this.getAreaLs()
  },
  methods: {
    setBusinessAreaId (id) {
       this.$emit('update:businessAreaId', id)
    },
    // 获取区域数据
    async getAreaLs () {
       const res = await businessTableArea.businessAreaList({
        businessAreaType: 0,
        businessId: this.shopInfo.id
      })
      this.areaLs = res.data
    },
    // 修改/新增区域
    businessTableAreaLongpress (item) {
      this.$prompt('请输入区域名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.businessAreaName,
        inputValidator: (value) => {
          if (value) {
            return true
          }
          return '请输入分组名称'
        }
      }).then(async ({ value }) => {
        const res = await businessTableArea.businessTableArea({
          sort: this.areaLs.length - 1,
          businessId: this.shopInfo.id,
          ...item,
          businessAreaName: value,
          businessAreaType: 0
        })
        if (res.success) {
           this.$emit('updata')
          this.getAreaLs()
        }
      })
    },
       // 删除区域
    async businessTableAreaClose (id) {
       const confirm = await this.$confirm('是否删除该区域?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (confirm === 'confirm') {
       const res = await businessTableArea.businessTableAreaDel(id)
        if (res.success) {
          this.getAreaLs()
          this.$emit('updata')
          this.$message({
            message: '区域删除成功',
            type: 'success'
          })
        }
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.tableArea {
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  height: 264px;
  padding: 25px 25px;
  box-sizing: border-box;
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 20px;
    margin-bottom: 24px;
  }
  &__body {
       &--add{
        border: 1px dashed #000000;
        border-radius: 6px;
        width: 65px;
        height: 27px;
        color: #000000;
        font-size: 12px;
        text-align: center;
        line-height: 27px;
        display: inline-block;
        cursor: pointer;
       }
  }
}
::v-deep .el-tag {
  cursor: pointer;
  margin-bottom: 10px;
}
.tableArea__body--tab + .tableArea__body--tab {
  margin-left: 10px;
}
::v-deep.el-tag{
  margin-right: 30px;
}
</style>
