<template>
  <div
    :class="{
      areaItem: true,
      activeArea: activeArea,
    }"
    @click.stop="tapArea"
  >
    <div class="areaItem__name">{{ areaItem.businessTableMark }}桌</div>
    <div class="areaItem__moeny" v-if="showTableNum">￥{{ areaItem.actualPrice || 0 }}</div>
    <div class="areaItem__parson">{{ parsonNum }}人</div>
    <i @click.stop="confirm"  v-if="showTableNum" class="el-icon-circle-plus-outline"></i>
  </div>
</template>
<script>
export default {
  props: {
    areaItem: {
      type: Object,
      default: () => {}
    },
    activeArea: {
      type: Boolean,
      default: false
    },
    showTableNum: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      seatNum: ''
    }
  },
  computed: {
     parsonNum () {
      if (!this.showTableNum) {
        return this.areaItem.seatNum
      }
        return this.areaItem.repastUserNum || 0
     }
  },
  methods: {
    tapArea () {
      this.$emit('click')
    },
    // 新增订单
    confirm () {
      this.$addOrderDialog({
        show: true,
        parsonNum: this.areaItem.seatNum,
        successCD: (num) => {
           this.$router.push({
        path: '/layout/shop',
        query: {
          persontNum: num,
          businessTableId: this.areaItem.id,
          businessTableMark: this.areaItem.businessTableMark,
          businessAreaId: this.areaItem.businessAreaId
        }
      })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.areaItem {
  background: #f7f7fc;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 186px;
  height: 186px;
  display: inline-block;
  margin-left: 40px;
  margin-bottom: 25px;
  padding-left: 27px;
  padding-top: 10px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  &__name {
    color: #999999;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 32px;
  }
  &__moeny {
    color: #000000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 48px;
  }
  &__parson {
    color: #999999;
    font-weight: 400;
    font-size: 16px;
    position: absolute;
    bottom: 20px;
  }
  &__popover {
    width: 400px;
    height: 250px;
    display: flex;
    flex-direction: column;
    &--title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 20px;
    }
    &--body {
      flex: 1;
      min-height: 0px;
      span {
        margin-right: 3.9px;
        margin-bottom: 8px;
        cursor: pointer;
      }
    }
  }
}
.areaItem:nth-child(4n-3) {
  margin-left: 0px;
}
.el-icon-circle-plus-outline {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 30px;
}
.activeArea {
  background: #ffffff;
  border: 1px solid #ffbb12;
  box-sizing: border-box;
  .el-icon-circle-plus-outline {
    color: #ffbb12;
  }
}

::v-deep .el-scrollbar {
  overflow-y: hidden !important;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto !important;
}
::v-deep .is-horizontal {
  display: none;
}
::v-deep .el-tag {
  width: 88px;
  text-align: center;
  margin-right: 2px;
}
::v-deep.el-popover{
  padding: 0px!important;
  width: 400px!important;
}
</style>
